@import '@nextgen-web-framework/all/amway-design-system.scss';

#ada-custom-buttom {
  position: fixed;
  z-index: 4;
  width: 56px;
  height: 56px;
  @include flexbox-positional-style($display-flex, center, center);

  left: calc(100vw - (100vw - 100%) - 96px);
  cursor: pointer;
  border-radius: 100px;
  box-shadow: 0px 2px 8px 0px rgba(44, 44, 44, 0.5);
}

.ada-bottom,
.is-sticky-bottom {
  bottom: 24px;
}
.chatbot-container {
  width: 100%;
}
@include sm-mobile() {
  #ada-custom-buttom {
    left: calc(100vw - (100vw - 100%) - 72px);
  }

  .is-sticky-bottom {
    bottom: calc(var(--stickyBottomBarHeight) + 17px + env(safe-area-inset-bottom, 0));
  }
}

@include xs-mobile() {
  .ada-bottom {
    bottom: calc(84px + env(safe-area-inset-bottom, 0));
  }

  .is-sticky-bottom {
    bottom: calc(var(--stickyBottomBarHeight) + 83px + env(safe-area-inset-bottom, 0));
  }

  .chatbot-on-scroll {
    .is-sticky-bottom {
      bottom: calc(var(--stickyBottomBarHeight) + 17px + env(safe-area-inset-bottom, 0));
    }
  }
}
