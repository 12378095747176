@import '@nextgen-web-framework/all/amway-design-system.scss';

.login-page-container {
  @include flexbox-positional-style($display-flex, center, center);
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: auto;
}

@include sm-mobile() {
  .login-page-container {
    .login-wrapper {
      width: 524px;
    }
  }
}
@include xs-mobile() {
  .login-page-container {
    .login-wrapper {
      width: 100%;
      margin: 24px 16px;
    }
  }
}
